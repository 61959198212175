<template>
  <div class="list home">
    <h1 class="home_title">欢迎您使用东都多功能MMS系统！<span>(点击右上角<i class="icon-copy"></i>，可一键应用模版设置)</span></h1>
    <div class="home_box">
      <div class="home_c">
        <div class="home_a_1">通知公告</div>
        <div class="home_c_3">
          <div class="home_c_3_1">
            <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年11月29日更新公告</div>
              <p class="tou">1. 永久版升级为免费版本。自2024年12月1日起，东都MMS永久免费</p>
              <p class="tou">2. 原年费版升级为年VIP</p>
              <p class="tou">3. 系统界面调整</p>
              <p class="tou">4. 支持所有用户的会员自助公众号查账。</p>
            </div>
            <!-- <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年9月19日更新公告</div>
              <p class="tou">1. 显示细节优化。</p>
              <p class="tou">2. 服务器例行维护</p>
            </div>
            <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年7月29日更新公告</div>
              <p class="tou">1. 增加系统设置模板。</p>
              <p class="dongduRed">系统模版可以一键应用默认设置（详情请在电脑端右上角第二个图标、手机端 我的-系统设置-应用模版设置 了解）</p>
              <p class="tou">2. 增加系统版本，用户可灵活选择版本</p>
              <p class="tou">3. 服务器例行维护</p>
            </div>
            <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年6月14日更新公告</div>
              <p class="tou">1. 会员消费通知增加短信类型。</p>
              <p class="dongduRed">会员消费通知增加短信类型，每个会员可单独定制消息通知类型，每个店也可批量设置（通知类型、会员范围）。（批量设置在系统设置-门店管理-会员批量设置）</p>
              <p>现已正式开通用户，每店赠送100条通知短信</p>
              <p class="tou">2. 增加系统设置-订单记录。（权限同操作记录查看）</p>
              <p class="tou">3. 增加系统设置-短信发送记录（权限同操作记录查看）</p>
              <p class="tou">4. 服务器例行维护</p>
            </div>
            <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年4月16日更新公告</div>
              <p class="tou">1. 修复删除员工后提成不显示名字的问题。</p>
              <p class="tou">2. 部分功能性优化</p>
              <p class="tou">3. 服务器例行维护</p>
            </div>
            <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年3月27日更新公告</div>
              <p class="tou">1. 增加会员固定价格</p>
              <p class="dongduRed">每一个消费项目均支持会员固定价格、级别、会员等级固定价格（如：门店剪发38元，店长58元，可设置所有会员30元，也可设置钻石会员25，其他会员30，店长剪发同理可设置所有会员50，某某级别40、30、等等）</p>
              <p>提示：设置消费项目时，请用有权限的账号登录</p>
              <p class="tou">2. 部分性能优化</p>
              <p>优化手机端展示方式。</p>
              <p>部分BUG修复</p>
            </div>
            <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年3月11日更新公告</div>
              <p class="tou">1. 增加员工底薪机制</p>
              <p>支持为每一位员工设置底薪，默认为0</p>
              <p>增加员工工资页面，非主账号需要分配权限（主账号可以在系统管理-级别设置-权限设置里面修改每个级别的权限）。</p>
              <p class="tou">2. 部分性能优化</p>
              <p>优化平板显示。</p>
              <p>部分BUG修复</p>
            </div>
            <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年1月21日更新公告</div>
              <p class="tou dongduRed">本公司于2024年01月24日放假，届时将暂停更新(客服正常)，望悉知。同时也祝广大用户新春快乐！</p>
              <p class="tou">1. 移动端（微信小程序、app）支持系统设置、注册试用</p>
              <p>PC端设置功能同步至移动端，支持手机操作全部设置功能</p>
              <p>移动端支持注册试用，同设备只能试用一次。</p>
              <p class="tou">2. 电脑端(PC端)登录、注册页面优化</p>
              <p>优化登录、注册页面，使之更加符合人机交互逻辑，同时更加美观大气。同时，本系统会持续优化界面，力争做到用着方便，看着舒心。</p>
              <p class="tou">3. 电脑端(PC端)右上角退出登录变更为安全退出</p>
              <p class="dongduRed">安全退出会删除本机本账号所有的登录数据，包括但不限于连续登录状态、导航栏、等等……(普通退出关闭页签/浏览器即可)</p>
            </div>
            <div class="home_c_3_1_1">
              <div class="home_c_3_1_1_1">东都MMS 2024年1月12日更新公告</div>
              <p class="tou">1. 结账&挂单、充值、开卡</p>
              <p>统一页面风格，并且优化操作逻辑，使之更加符合人机交互逻辑，增加用户体验。</p>
              <p>开卡、充值不再必选充值套餐，使充值更加灵活。充值服务人员也不再必选。微信小程序同步更新。</p>
              <p class="tou">2. 微信小程序-排行</p>
              <p>微信小程序排行界面点击对应人员可快速跳入对应详情。(除自己外，需要有对应详情的查看权限)</p>
              <p class="tou">3. 员工提成</p>
              <p>员工提成页面增加异常记录查询选择框，方便更快定位可能异常记录。</p>
              <p class="tou">5. 设置帮助</p>
              <p>右上角三个图标中间的为设置帮助连接，打开可看到详细的设置介绍。</p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="home_b">
        <div class="home_a_1">小程序公众号二维码</div>
        <div class="home_a_2">
          <div class="home_a_2_1"><p>小程序端</p><img class="dongduXCXImg" src="https://www.dongdumms.com/images/xcx.jpg"></div>
          <div class="home_a_2_1"><p>会员公众号</p><img class="dongduGZHImg" src="https://www.dongdumms.com/images/ddhygzh.jpg"></div>
        </div>  
      </div>
      <div class="home_a">
        <div class="home_a_1">账户信息</div>
          <ul>
            <li><div class="left_title">账号：</div>{{User.userName}}</li>
            <li><div class="left_title">姓名：</div>{{User.xingming}}</li>
            <li><div class="left_title">性别：</div>{{User.xingbie}}</li>
            <li><div class="left_title">生日：</div>{{getDate(User.shengri)}}</li>
            <li><div class="left_title">注册日期：</div>{{getDate(User.zhuceriqi)}}</li>
            <li><div class="left_title">手机号：</div>{{User.phone}}</li>
            <li v-if="$store.state.User.jibieId==0"><div class="left_title">门店：</div><select v-model="fenpeimendianId" @change="qiehuanmendian">
              <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select></li>
            <li v-else><div class="left_title">门店：</div>{{User.fenpeimendianName}}</li>
            <li><div class="left_title">级别：</div>{{User.jibieId==0?'主账户':User.jibieName}}</li>
            <!-- <li><div class="left_title">推荐码：</div><strong>{{13236520+User.id}}</strong></li>
            <li><div class="left_title">推荐积分：</div>{{User.jifen}}</li> -->
          </ul>
      </div>
      <!-- <div class="home_c">
        <div class="home_a_1">积分兑换<a href="javascript:void(0)" @click="goUrl('/exchange','积分兑换')">更多>></a></div>
        <div class="home_c_1">当前积分：<strong>{{User.jifen}}</strong><p><i class="icon-info"></i>每成功推荐一位用户<strong>正式开通</strong>本系统可获取<strong>3000</strong>积分</p></div>
        <div class="home_c_2">
          <ul>
            <li v-for="item in dataList" :key="item.id" @click="openExWin(item.id)"><img src="@/assets/images/bxt.png"><p>{{item.name}}</p><p><strong>{{item.jifen}}</strong>积分</p></li>
          </ul>
        </div>
      </div> -->
      <div class="home_b">
        <div class="home_a_1">门店平账<select v-model="month" @change="getMDPZ" v-if="$store.state.User.jibieId==0"><option :value="item.id" v-for="item in months" :key="item">{{item.name}}月</option></select></div>
          <ul>
            <li v-if="$store.state.User.jibieId!=0" class="noData">请用主账号查看平账信息</li>
            <li v-else-if="$store.state.User.daoqishijian<0" class="noData">开通vip可查看平账信息</li>
            <li v-else-if="pingzhangList.length==0" class="noData">本月无需平账</li>
            <li v-for="(item,i) in pingzhangList" :key="i">门店 <span>{{item.hymdName}}</span> 会员在门店 <span>{{item.xfmdName}}</span> 共计消费 <span>{{item.fk}}</span> 元</li>          
          </ul>
      </div>
      <div class="home_b"></div>
      <div class="home_b"></div>
      <div class="home_b"></div>
    </div>
    <exchange-window v-if="exchangeShow" @closdMeg="exchangeShow=false" :exId="exId"></exchange-window>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
import { mapMutations } from "vuex"
import exchangeWindow from '@/views/exchange/exchangeWindow.vue'
export default {
  name: 'home',
  data() {
    return {
      fenpeimendianId:0,
      exchangeShow:false,
      exId:0,
      storeList:[],
      dataList:[],
      User:{},
      months:[],
      month:moment().format("YYYY-MM"),
      pingzhangList:[],
      form:{
        startDate:moment().startOf("M").format("YYYY-MM-DD"),
        endDate:moment().endOf("M").format("YYYY-MM-DD"),
      }
    }
  },
  created() {
    this.getStore()
    // this.getExchange()
    for(let i=0;i<7;i++){
      this.months.push({
        id:moment().subtract(i,"M").format("YYYY-MM"),
        name:moment().subtract(i,"M").month()+1
      })
    }
  },
  components: {exchangeWindow},
  mounted() {
  },
  methods: {
    ...mapMutations(['addVueXUser','addNav']),
    openExWin(id){
      this.exId=id
      this.exchangeShow=true
    },
    getMeg(){
      axios.post('/getInfoById').then((res) => {
        if(res.code===5000){
          this.User=res.data
          this.fenpeimendianId=this.User.fenpeimendianId
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getStore() {
      axios.post('/store/list').then((res) => {
        if (res.code === 5000) {
          this.storeList = res.data
          this.getMeg()
          if(this.$store.state.User.jibieId==0&&this.$store.state.User.daoqishijian>=0) this.getMendianzhangdan()
        } else {
          this.$Mesg({ content: res.msg, errOrSuc: 'err' })
        }
      })
    },
    getMDPZ(){
      this.form.startDate=moment(this.month).startOf("M").format("YYYY-MM-DD")
      this.form.endDate=moment(this.month).endOf("M").format("YYYY-MM-DD")
      this.getMendianzhangdan()
    },
    getMendianzhangdan(){
      if(this.$store.state.User.daoqishijian>=0){
        axios.post('/mendianzhangdan',this.form).then((res) => {
          if (res.code === 5000) {
          this.pingzhangList=res.data.map(e=>{
            let hymd=this.storeList.find(a=>a.id==e.hymdId)
            let xfmd=this.storeList.find(a=>a.id==e.fenpeimendianId)
            return{
              ...e,
              hymdName:hymd.storeName||'',
              xfmdName:xfmd.storeName||'',
            }
          })
          } else {
            this.$Mesg({ content: res.msg, errOrSuc: 'err' })
          }
        })
      }else {
        this.$Mesg({ content:'您所属门店VIP已到期，无法查看平账信息', errOrSuc: 'err' })
      }
      
    },
    getExchange() {
      axios.post('/exchange/listPage', {pageSize:7,pageIndex:1}).then((res) => {
        if (res.code === 5000) {
          this.dataList = res.data
        } else {
          this.$Mesg({ content: res.msg, errOrSuc: 'err' })
        }
      })
    },
    qiehuanmendian(){
      let store=this.storeList.find((e)=>e.id==this.fenpeimendianId)
      axios.post('/qiehuanmendian',{fenpeimendianId:this.fenpeimendianId,fenpeimendianName:store.storeName}).then((res) => {
        if (res.code === 5000) {
          this.$Mesg({ content: res.msg})
          let users=JSON.parse(sessionStorage.getItem('dongduUser'))
          users.user.fenpeimendianId=this.fenpeimendianId
          users.user.fenpeimendianName=store.storeName
          users.user.daoqishijian=res.dq
          users.token=res.token
          sessionStorage.setItem('dongduUser',JSON.stringify(users))
          this.addVueXUser(users.user)
          this.$parent.$parent.addDate(res.date,res.dq)
        } else {
          this.$Mesg({ content: res.msg, errOrSuc: 'err' })
        }
      })
    },
    getDate(str) {
      if (str) return moment(str).format('YYYY-MM-DD')
      else return ''
    },
    goUrl(url,name){
      let pa={
        name,
        path:url,
      }
      this.addNav(pa)
      this.$router.push(url)
    }

  },
}
</script>
