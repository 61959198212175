<template>
<div class="meg_all">
    <div class="meg smallMeg">
    <h1>兑换明细</h1>
    <div class="meg_a">
      <div class="meg_a_1">
        <div class="cont">
          <div class="cont_a">
            <div class="cont_a_1"><img src="@/assets/images/bxt.png"></div>
            <div class="cont_a_2">
              <div class="cont_a_2_1">名称：{{infoData.name}}</div>
              <div class="cont_a_2_1">类别：{{countFLList(infoData.fenlei)}}</div>
              <div class="cont_a_2_1">单价：<strong>{{infoData.jifen}}</strong>积分</div>
              <div class="cont_a_2_1">兑换门店：<select v-model="mendianId" :disabled="$store.state.User.jibieId!=0" @change="editFenpeimendianName"><option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option></select></div>
              <div class="cont_a_2_1">剩余数量：{{infoData.num}}</div>
              <div class="cont_a_2_2"><button type="button" class="ljdh" @click="duihuan">立即兑换</button></div>
            </div>
          </div>
          <div class="cont_b">
            {{infoData.body}}
          </div>
        </div>
      </div>
    </div>
    <div class="meg_c"><button type="button" class="off" @click="closdMeg()">关闭</button></div>
    <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
    </div>
</div>
</template>

<script>
import axios from '@/http/axios'
import { exchangeFLList } from '@/data/data'
export default {
  name: 'home',
  props: {exId:Number},
  data () {
    return {
      infoData:{},
      storeList:[],
      mendianId:this.$store.state.User.fenpeimendianId,
      mendianName:this.$store.state.User.fenpeimendianName,
    }
  },
  created () {
    this.init()
  },
  mounted () { },
  components: {},
  methods: {
    editFenpeimendianName(){
      let store=this.storeList.find(e=>e.id==this.mendianId)
      if(store) this.mendianName=store.storeName
      else this.mendianName=''
    },
    duihuan(){
      axios.post('/exchange/exchangeXTSJ',{fenpeimendianId:this.mendianId,exId:this.exId,fenpeimendianName:this.mendianName}).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: res.msg})
          this.closdMeg()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    countFLList(fl){
      let fenlei=exchangeFLList.find(e=>e.id==fl)
      if(fenlei) return fenlei.name
      else return ''
    },
    getStore() {
      axios.post('/store/list').then((res) => {
        if (res.code === 5000) {
          this.storeList = res.data
        } else {
          this.$Mesg({ content: res.msg, errOrSuc: 'err' })
        }
      })
    },
    getData(){
      axios.post('/exchange/getInfoById',{id:this.exId}).then((res) => {
        if(res.code===5000){
          this.infoData=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    init(){
      this.getStore()
      this.getData()
    },
    closdMeg () {
      this.$emit('closdMeg')
    },
  }
}
</script>
